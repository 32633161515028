import { SumberHadits } from "../../Quotes";
import Skeleton from "react-loading-skeleton";
import parse from "html-react-parser";
import { useState } from "react";
import {
  currentNumbering,
  haditsNumberingSources,
} from "../../../Detail Topic/components/topic/Card Hadits/BoxHadits";

interface BoxHaditsProps {
  sumberHadits: SumberHadits;
}

const BoxHadits = ({ sumberHadits }: BoxHaditsProps) => {
  const [isShowHaditsNumber, setIsShowHaditsNumber] = useState<boolean>(false);

  const handleShowHaditsNumber = () => {
    setIsShowHaditsNumber(!isShowHaditsNumber);
  };

  // to handle kitab that has no number of book
  const volumeOrBook = (perawi: string): string => {
    if (perawi === "HR. Tirmidzi") return "Volume";
    else return "Book";
  };

  return (
    <div>
      {/* hadits number */}
      {isShowHaditsNumber &&
        currentNumbering.indexOf(sumberHadits.perawi) > -1 && (
          <div className="absolute bg-white px-5 py-4 rounded-3xl mt-[-6.5rem] sm:mt-[-6.4rem] w-max ml-[-5rem] webkit-box-shadow-secondary">
            <h4 className="text-[15px] font-bold text-secondary-black mb-3">
              {volumeOrBook(sumberHadits.perawi)} {sumberHadits.book || "-"}:
              Chapter {sumberHadits.chapter || "-"}: Number{" "}
              {sumberHadits.number || "-"}
            </h4>
            <p className="text-[15px] font-medium text-secondary-black/65">
              {parse(
                haditsNumberingSources[
                  sumberHadits.perawi as
                    | "HR. Muslim"
                    | "HR. Bukhari"
                    | "HR. Tirmidzi"
                    | "HR. An-Nasa'i"
                    | "HR. Ibnu Majah"
                    | "HR. Abu Daud"
                ]
              )}
            </p>
          </div>
        )}
      {/* perawi hadits */}
      <div
        onMouseEnter={handleShowHaditsNumber}
        onMouseLeave={handleShowHaditsNumber}
        className="bg-[#35CAE5]/[0.075] hover:border-[#32BFD8] border-[#35CAE5]/[0.005] text-[#32BFD8] text-[15.5px] sm:text-[16px] font-semibold border-[1px] border-solid cursor-pointer rounded-full px-5 py-1.5 mt-4"
      >
        {sumberHadits.perawi || <Skeleton borderRadius={24} />}
      </div>
    </div>
  );
};

export default BoxHadits;
