import HaditsReference from "./HaditsReference";

// urutkan  berdasarkan abjad
const haidtsReferenceData = [
  "Al-Bukhari, M. (2020) Sahih Al-Bukhari. Compiled by M. Mohee Uddin.",
  "Muslim. (2018) Sahih Muslim. Translated by A. Hameed Siddiqui.",
  "Imam of the Hadeeth. (2008). Sunan al-Tirmidhi. Translated by Haytham Kreidly. Dar Al-Kotob Al-Ilmiya.",
  "Imam An-Nasa’i. (2008). Sunan An-Nasa’i. Translated by Mohammad Mahdi al-Sharif. Dar Al-Kotob Al-Ilmiyah",
  "Abu Abdullah ibn Majah. (2008). Sunan Ibn Majah. Translated by Mohammad Mahdi al-Sharif. Dar Al-Kotob Al-Ilmiyah",
  "Abu Dawud As-Sijistani. (2008). Sunan Abu Dawud. Translated by Mohammad Mahdi al-Sharif. Dar Al-Kotob Al-Ilmiyah",
  "Almanhaj.or.id. Tersedia di: https://almanhaj.or.id",
  "HadeethEnc.com. Tersedia di: https://hadeethenc.com",
  "Muslim.or.id. Tersedia di: https://muslim.or.id",
  "Rumaysho.com. Tersedia di: https://rumaysho.com",
  "Sunnah.com. Tersedia di: https://sunnah.com",
];

const ListHaditsReference = () => {
  return (
    <div className="mt-8 space-y-5 overflow-y-auto max-h-96 drop-shadow-secondary-blue">
      {haidtsReferenceData.map((reference, index) => (
        <HaditsReference label={reference} number={(index + 1).toString()} />
      ))}
    </div>
  );
};

export default ListHaditsReference;
