import {
  collection,
  query,
  getDocs,
  getCountFromServer,
  where,
} from "firebase/firestore";
import { CSSTransition } from "react-transition-group";
import Hadits from "./components/hadits/Hadits";
import BottomNavigation from "./components/navigation/BottomNavigation";
import ListSavedQuotes from "./components/saved quotes/ListSavedQuotes";
import TopNavigation from "./components/navigation/TopNavigation";
import { ComponentRef, useEffect, useRef, useState } from "react";
import { db } from "../../firebase";
import "./Slider.css";

export interface SumberHadits {
  book: string;
  chapter: string;
  number: string;
  perawi: string;
}

export interface QuotesHadits {
  id: string;
  sumberHadits: SumberHadits;
  isiHadits: string;
}

const Quotes = () => {
  const [quotesHadits, setQuotesHadits] = useState<QuotesHadits[]>([]);
  const nodeRef = useRef(null);
  const [counter, setCounter] = useState<number>(0);
  const [isOpenSavedQuotes, setIsOpenSaveQuotes] = useState<boolean>(false);

  const haditsRef = useRef<ComponentRef<typeof Hadits>>(null);

  const handleOpenSavedQuotes = () => setIsOpenSaveQuotes(!isOpenSavedQuotes);

  const handleShare = () => {
    // call child function
    haditsRef?.current?.shareHadits();
  };

  const counterIncrement = () => {
    fetchRandomHadits();
    setCounter((prevCount) => prevCount + 1);
  };

  const counterDecrement = () => {
    // if get to minimum data length
    if (counter - 1 < 0) {
      setCounter(quotesHadits.length - 1);
    } else setCounter((prevCount) => prevCount - 1);
  };

  const showSavedQuotes = (hadits: QuotesHadits) => {
    const listQuotesHadits = quotesHadits;
    // insert saved quotes to listQuotesHadits
    listQuotesHadits.splice(counter, 0, hadits);
    setQuotesHadits(listQuotesHadits);
    // close list quotes
    handleOpenSavedQuotes();
  };

  const fetchRandomHadits = async () => {
    // get max document index
    const coll = collection(db, "quotes-updated");
    const q = query(coll);
    let documentMaxIndex: any = await getCountFromServer(q);
    documentMaxIndex = documentMaxIndex.data().count;
    const randomQuotesNum = Math.floor(Math.random() * documentMaxIndex) + 1;

    const querySnapshot = await getDocs(
      query(
        collection(db, "quotes-updated"),
        where("nomorQuotes", "==", randomQuotesNum)
      )
    );
    const data: QuotesHadits[] = querySnapshot.docs.map((docSnapshot) => {
      return {
        id: docSnapshot.id,
        sumberHadits: docSnapshot.data().sumberHadits,
        isiHadits: docSnapshot.data().isiHadits,
      };
    });
    setQuotesHadits([...quotesHadits, data[0]]);
  };

  useEffect(() => {
    fetchRandomHadits();
  }, []);

  return (
    <div className="overflow-x-hidden overflow-y-hidden" id="image-to-share">
      <TopNavigation handleOpenSavedQuotes={handleOpenSavedQuotes} />

      {/* quotes */}
      <CSSTransition
        nodeRef={nodeRef}
        in={counter % 2 === 0}
        timeout={700}
        classNames="slide"
      >
        <div ref={nodeRef}>
          <Hadits
            ref={haditsRef}
            sumberHadits={quotesHadits[counter]?.sumberHadits || ""}
            isiHadits={quotesHadits[counter]?.isiHadits || ""}
          />
        </div>
      </CSSTransition>

      {/* bottom navigation */}
      {/* !isOpenSavedQuotes is for re-rendering saved icon when closo list saved quotes */}
      {!isOpenSavedQuotes && (
        <BottomNavigation
          currentCounter={counter}
          hadits={quotesHadits[counter]}
          handleShare={handleShare}
          counterIncrement={counterIncrement}
          counterDecrement={counterDecrement}
        />
      )}

      {/* saved quotes */}
      <ListSavedQuotes
        showSavedQuotes={(hadits) => showSavedQuotes(hadits)}
        isOpen={isOpenSavedQuotes}
        handleOpen={handleOpenSavedQuotes}
      />
    </div>
  );
};

export default Quotes;
