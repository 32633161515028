import { useState } from "react";
import { SumberHadits } from "../../../../Quotes/Quotes";
import parse from "html-react-parser";

interface BoxHaditsProps {
  sumberHadits: SumberHadits;
  index: number;
}

// sources list for hadits numbering
export const haditsNumberingSources = {
  "HR. Muslim":
    "Muslim. (2018) Sahih Muslim. <br /> Translated by A. Hameed Siddiqui.",
  "HR. Bukhari":
    "Al-Bukhari, M. (2020) Sahih Al-Bukhari. <br /> Compiled by M. Mohee Uddin.",
  "HR. Tirmidzi":
    "At-Tirmidzi. (2008). Sunan Al-Tirmithi. <br /> Translated by Haytham Kreidly.",
  "HR. An-Nasa'i":
    "An-Nasa’i. (2008). Sunan An-Nasa’i. <br /> Translated by Mohammad Mahdi al-Sharif",
  "HR. Ibnu Majah":
    "Ibnu Majah. (2008). Sunan Ibnu Majah. <br /> Translated by Mohammad Mahdi al-Sharif.",
  "HR. Abu Daud":
    "Abu Daud. (2008). Sunan Abu Dawud. <br /> Translated by Mohammad Mahdi al-Sharif.",
};

// temp code, because currently, I only have muslim and bukhari
export const currentNumbering = [
  "HR. Bukhari",
  "HR. Muslim",
  "HR. Tirmidzi",
  "HR. An-Nasa'i",
  "HR. Ibnu Majah",
  "HR. Abu Daud",
];

const BoxHadits = ({ sumberHadits, index }: BoxHaditsProps) => {
  const [isShowHaditsNumber, setIsShowHaditsNumber] = useState<boolean>(false);

  const handleShowHaditsNumber = () => {
    setIsShowHaditsNumber(!isShowHaditsNumber);
  };

  // to handle kitab that has no number of book
  const volumeOrBook = (perawi: string): string => {
    if (perawi === "HR. Tirmidzi") return "Volume";
    else return "Book";
  };

  return (
    <div className="">
      {/* hadits number */}
      {isShowHaditsNumber &&
        currentNumbering.indexOf(sumberHadits.perawi) > -1 && (
          <div
            className={`absolute bg-white px-5 py-4 rounded-3xl webkit-box-shadow-secondary mt-[-6.8rem] w-max ${
              index % 2 === 0 ? "ml-none" : "ml-[-9rem]"
            }`}
          >
            <h4 className="text-[15px] font-bold text-secondary-black mb-3">
              {volumeOrBook(sumberHadits.perawi)} {sumberHadits.book || "-"}:
              Chapter {sumberHadits.chapter || "-"}: Number{" "}
              {sumberHadits.number || "-"}
            </h4>
            <p className="text-[15px] font-medium text-secondary-black/65">
              {parse(
                haditsNumberingSources[
                  sumberHadits.perawi as
                    | "HR. Muslim"
                    | "HR. Bukhari"
                    | "HR. Tirmidzi"
                    | "HR. An-Nasa'i"
                    | "HR. Ibnu Majah"
                ]
              )}
            </p>
          </div>
        )}
      {/* hadits perawi */}
      <div
        onMouseEnter={handleShowHaditsNumber}
        onMouseLeave={handleShowHaditsNumber}
        key={index}
        className={`${
          index % 2 === 0
            ? "bg-[#35CAE5]/[0.075] text-[#32BFD8] hover:border-[#32BFD8] border-[#35CAE5]/[0.005]"
            : "bg-[#38DCD8]/[0.075] text-[#2fc7c3] hover:border-[#2fc7c3] border-[#38DCD8]/[0.005]"
        }  mr-2 mt-2 text-[15px] font-semibold rounded-3xl px-[0.85rem] py-[0.3rem] w-max border-[1px] border-solid cursor-pointer`}
      >
        {sumberHadits.perawi}
      </div>
    </div>
  );
};

export default BoxHadits;
